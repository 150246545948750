import {polygonAmoy} from "@wagmi/core/chains";

export const USDC_CONTRACT = "0xFEca406dA9727A25E71e732F9961F680059eF1F9";

export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const CROSSMINT_PROJECT_ID = process.env.REACT_APP_CROSSMINT_PROJECT_ID;
export const CROSSMINT_ENVIRONMENT = process.env.REACT_APP_CROSSMINT_ENVIRONMENT;
export const BASE_URL2 = process.env.REACT_APP_BASE_URL2;

export const WEB_URL = process.env.REACT_APP_MINT_URL;
export const IPFS_URL = process.env.REACT_APP_IPFS_URL;
export const POLYGONSCAN_URL = process.env.REACT_APP_POLYGONSCAN_URL;
export const OPENSEA_URL = process.env.REACT_APP_OPENSEA_URL;

export const INFURA_URL = process.env.REACT_APP_INFURA_URL;

export * from "./helpers";

export const enabledChains = [polygonAmoy];
export const wagmiProjectId = process.env.REACT_APP_PROJECT_ID;
