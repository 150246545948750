import React, { useEffect } from "react";
import {
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction,
} from "wagmi";

import USDC from "../../ABI/USDC.json";
import { Button } from "react-bootstrap";
import Loading from "../Loading";
import { enabledChains, USDC_CONTRACT } from "../../utils";

export default function Approve({ contract, price, onSuccess, onError }: any) {
  const { config } = usePrepareContractWrite({
    address: USDC_CONTRACT,
    abi: USDC,
    functionName: "approve",
    chainId: enabledChains[0].id,
    args: [contract, price],
  });

  const { data, error, isLoading, write } = useContractWrite(config);

  const {
    data: transactionData,
    error: transactionError,
    isLoading: isLoadingTransaction,
  } = useWaitForTransaction({
    hash: data?.hash,
  });

  useEffect(() => {
    if (transactionData) {
      onSuccess(transactionData);
    }
    // eslint-disable-next-line
  }, [transactionData]);

  useEffect(() => {
    if (error) {
      onError(error);
    }
    // eslint-disable-next-line
  }, [error]);

  useEffect(() => {
    if (transactionError) {
      onError(transactionError);
    }
    // eslint-disable-next-line
  }, [transactionError]);

  return (
    <>
      {isLoading || isLoadingTransaction ? (
        <Loading width={80} height={80} />
      ) : (
        <>
          {write ? (
            <Button
              variant="solid"
              onClick={async () => write()}
              className="mb-24"
            >
              Approve USDC spend
            </Button>
          ) : (
            <Loading width={80} height={80} />
          )}
        </>
      )}
    </>
  );
}
