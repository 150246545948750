import React from "react";
import Icon from "../assets/images/loading.svg";

function Loading({ width, height }: any) {
  return (
    <div style={{ marginBottom: 24 }}>
      <img src={Icon} style={{ width: width, height: height }} alt="Loading" />
    </div>
  );
}

export default Loading;
