import * as React from "react";
import { Routes, Route } from "react-router-dom";
import Footer from "./components/Footer";
import Header from "./components/Header";
import HomePage from "./pages/Home";
import LatestPage from "./pages/Latest";
import ArtistPage from "./pages/Artist";
import ArtistsPage from "./pages/Artists";
import DetailPage from "./pages/Detail";
import HowWorksPage from "./pages/HowWorks";
import ErrorPage from "./pages/Error";
import AboutUs from "./pages/AboutUs";

import { Provider } from "react-redux";
import { store } from "./store";
import ScrollToTop from "./utils/scrollToTop";

function App() {
  return (
    <>
      <Provider store={store}>
        <div className="puretrade-app">
          <Header />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/latest" element={<LatestPage />} />
            <Route path="/artists" element={<ArtistsPage />} />
            <Route path="/artist/:permalink" element={<ArtistPage />} />
            <Route path="/artist/:permalink/:id" element={<DetailPage />} />
            <Route path="/how-it-works" element={<HowWorksPage />} />{" "}
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/error" element={<ErrorPage />} />
          </Routes>

          <Footer />
        </div>
        <ScrollToTop />
      </Provider>
    </>
  );
}

export default App;
