import React, { useRef, useState } from "react";
import Arrow from "../assets/images/arrow-square-right.svg";

// Import Swiper React components
// import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import Card from "./CardItem";
import { getImage } from "../utils";

function SwiperSlider({ tracks }: any) {
  const swiperRef = useRef() as any;
  const [prev, setPrev] = useState(0);
  return (
    <div className="swipe-outer">
      <div className="swipe-btns">
        <button
          className="btn-reset swipe-btns-prev"
          disabled={prev > 1 ? false : true}
          onClick={() => {
            swiperRef.current?.slidePrev();
            setPrev(prev - 1);
          }}
        >
          <img src={Arrow} alt="prev" />
        </button>
        <button
          className="btn-reset swipe-btns-next"
          onClick={() => {
            swiperRef.current?.slideNext();
            setPrev(prev + 1);
          }}
          disabled={prev > tracks.length}
        >
          <img src={Arrow} alt="next" />
        </button>
      </div>

      <Swiper
        // modules={[Navigation]}
        spaceBetween={0}
        slidesPerView={1}
        // navigation
        // loop={true}
        onSlideChange={(e) => {
          setPrev(e.activeIndex + 1);
        }}
        onBeforeInit={(swiper) => {
          swiperRef.current = swiper;
        }}
        // allowTouchMove={false}
        navigation={{
          nextEl: ".next",
        }}
        breakpoints={{
          480: {
            slidesPerView: 2,
            spaceBetween: 5,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 0,
          },
          1200: {
            slidesPerView: 4,
            spaceBetween: 0,
          },
        }}
      >
        {tracks.map((track: any) => (
          <SwiperSlide key={track.id}>
            <Card
              id={track.id}
              name={track.name}
              artist={track.artist}
              image={getImage(track.image)}
              user_id={track.user_id}
              contract={track.contract}
              transaction_data={track.transaction_data}
              animation_url={track.animation_url}
              permalink={track.permalink}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

export default SwiperSlider;
