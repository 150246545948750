import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import ImageError from "../assets/images/404icon.svg";

function Error({ number }: any) {
  return (
    <main className="page-content">
      <div className="pt-40 pb-80 bg-shape">
        <div className="col-grid mb-80">
          <Container>
            <Row className="justify-content-lg-between align-items-center justify-content-center">
              <Col xs={12} lg={12} className="mb-lg-0 mb-4 error-page">
                <img src={ImageError} alt="404" />
                <h1>Ops, this record is scratched!</h1>
                <p>Which means the page probably doesn't exist.</p>
                <Link to={"/"} className="btn btn-solid btn-lg">
                  Back Home
                </Link>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </main>
  );
}

export default Error;
