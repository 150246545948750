import React from "react";
import { useContractRead } from "wagmi";

import MineMintToken from "../../ABI/MineMintToken.json";
import { enabledChains } from "../../utils";

export default function Sold({ token, contract }: any) {
  const configTotalSupply = {
    address: contract,
    abi: MineMintToken,
    functionName: "totalSupply",
    chainId: enabledChains[0].id,
    args: [token],
  };

  const configMaxSupply = {
    address: contract,
    abi: MineMintToken,
    functionName: "_maxSupply",
    chainId: enabledChains[0].id,
    args: [token],
  };

  const getDataSold = (data: any) => {
    return Number(data.data);
  };

  const totalSupply = useContractRead(configTotalSupply);
  const maxSupply = useContractRead(configMaxSupply);

  return (
    <>
      {totalSupply ? getDataSold(totalSupply) - 1 : ""}/
      {maxSupply ? getDataSold(maxSupply) - 1 : ""}
    </>
  );
}
