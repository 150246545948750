import { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Dropdown,
  Form,
  InputGroup,
  Row,
} from "react-bootstrap";
import Search from "../assets/images/search.svg";
import Arrow from "../assets/images/arrow-down.svg";

import LoadingImg from "../assets/images/loading-small.svg";

import { ApplicationState, loadArtists } from "../store";
import { useSelector, useDispatch } from "react-redux";


import ArtistItem from "../components/ArtistItem";
import ArtistItemLoading from "../components/ArtistItemLoading";



function ArtistsPage() {
  const dispatch = useDispatch();
  const [artistsData, setArtistsData] = useState([]);
  const [searchText, setSearchText] = useState("");
  // eslint-disable-next-line
  const [openSearch, setOpenSearch] = useState(false);

  const [filterType, setFilterType] = useState(0);

  const { artists } = useSelector(
    (state: ApplicationState) => state.artistsReducer
  );

  const [offset] = useState(1);

  const [showLoadMore] = useState(true);

  const [loading, setLoading] = useState(true);

  const [loadingMore] = useState(false);

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = async () => {
    setLoading(true);
    await dispatch(loadArtists());
    setLoading(false);
  };

  useEffect(() => {
    if (searchText) {
      let searchData = artists.filter((q: any) => {
        return q.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
      });

      if (filterType === 0) {
        setArtistsData(sortRecent([...searchData]));
      } else if (filterType === 1) {
        setArtistsData(sortAZ([...searchData]));
      } else if (filterType === 2) {
        setArtistsData(sortZA([...searchData]));
      }
    } else {
      if (filterType === 0) {
        setArtistsData(sortRecent([...artists]));
      } else if (filterType === 1) {
        setArtistsData(sortAZ([...artists]));
      } else if (filterType === 2) {
        setArtistsData(sortZA([...artists]));
      }
    }
  }, [artists, searchText, filterType]);

  const sortRecent = (data: any) => {
    return data.sort((a: any, b: any) => {
      var textA = Number(a.updated);
      var textB = Number(b.updated);
      return textA > textB ? -1 : textA < textB ? 1 : 0;
    });
  };

  const sortAZ = (data: any) => {
    return data.sort((a: any, b: any) => {
      var textA = a.name.toUpperCase();
      var textB = b.name.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
  };

  const sortZA = (data: any) => {
    return data.sort((a: any, b: any) => {
      var textA = a.name.toUpperCase();
      var textB = b.name.toUpperCase();
      return textA > textB ? -1 : textA < textB ? 1 : 0;
    });
  };

  const loadMoreTracks = async (type: number, offset: number) => {};

  return (
    <main className="page-content bg-shape">
      <div className="pt-60 text-center mb-40">
        <Container>
          <div className="position-relative">
            <InputGroup>
              <Button variant="btn-reset">
                <img src={Search} alt="Seacrh" />
              </Button>
              <Form.Control
                placeholder="Search for artist"
                className="border-0"
                onChange={(e) => {
                  setOpenSearch(false);
                  setSearchText(e.target.value);
                  setTimeout(() => {
                    setOpenSearch(true);
                  }, 10);
                }}
              />
            </InputGroup>
          </div>
        </Container>
      </div>
      <div className="pb-80">
        <Container>
          <Row className="mb-4 align-items-center justify-content-between">
            <Col sm={6} md={6} className="mb-sm-0 mb-4">
              <h4 className="mb-0">
                {artistsData.length === 0 && searchText.length > 0
                  ? "No items found for this search"
                  : "Authors"}
              </h4>
            </Col>
            <Col sm={6} md={4} className="d-flex align-items-center">
              <h6 className="heading mb-0 me-3">Sort by</h6>
              <Dropdown className="flex-grow-1">
                <Dropdown.Toggle
                  variant="white"
                  className="d-flex align-items-center justify-content-between"
                >
                  <span>
                    {filterType === 0 && "Recent"}
                    {filterType === 1 && "A → Z"}
                    {filterType === 2 && "Z → A"}
                  </span>
                  <img src={Arrow} alt="Arrow" />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => {
                      setFilterType(0);
                    }}
                  >
                    Recent
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      setFilterType(1);
                    }}
                  >
                    A → Z
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      setFilterType(2);
                    }}
                  >
                    Z → A
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          </Row>
          <Row>
            {artistsData.map((artist: any) => (
              <Col sm={6} md={4} lg={3} key={String(artist.id)}>
                <ArtistItem
                  image={artist.image}
                  name={artist.name}
                  tracks={artist.tracks}
                  permalink={artist.permalink}
                />
              </Col>
            ))}

            {loading && (
              <>
                {[...Array(8)].map((elementInArray, index) => (
                  <Col sm={6} md={4} lg={3} key={String(index)}>
                    <ArtistItemLoading />
                  </Col>
                ))}
              </>
            )}
          </Row>

          {showLoadMore &&
            artistsData.length >= 8 &&
            artistsData.length % 8 === 0 && (
              <div className="text-center">
                <Button
                  variant="solid"
                  className="btn btn-lg"
                  onClick={() => loadMoreTracks(filterType, offset)}
                >
                  {loadingMore && (
                    <img
                      src={LoadingImg}
                      alt="Loading"
                      className="loading-box"
                    />
                  )}{" "}
                  LOAD MORE
                </Button>
              </div>
            )}
        </Container>
      </div>
    </main>
  );
}

export default ArtistsPage;
