import { Col, Container, Row } from "react-bootstrap";
import Artists from "../assets/images/home-left.png";
import icoVerify from "../assets/images/icoVerify.png";
import icoProcess from "../assets/images/icoProcess.png";
import icoAbastract from "../assets/images/icoAbastract.png";
import icoCollections from "../assets/images/icoCollections.png";

function HowWorkPage() {
  return (
    <main className="page-content">
      <div className="pt-40 pb-80 bg-shape">
        <div className="col-grid mb-80">
          <Container>
            <Row className="justify-content-lg-between align-items-center justify-content-center">
              <Col xs={12} lg={5} className="mb-lg-0 mb-4">
                <div className="col-grid-image col-grid-left">
                  <img src={Artists} alt="Artists" />
                  <h2 className="mb-0">
                    <span>How it works?</span>
                  </h2>
                </div>
              </Col>
              <Col xs={12} lg={5}>
                <h4 className="h4 mb-0">
                  If you’re a music professional, this section is for you!
                  PureTrade is a revolutionary NFT platform seamlessly integrated
                  with music distribution services, making it incredibly easy
                  for artists to mint NFTs alongside their traditional music
                  releases
                </h4>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="page-information">
          <Container fluid="sm">
            <h4>Effortless NFT Creation for Artists</h4>
            <p>
              By setting up an account with one of our partnered distribution
              platforms, you can create NFTs for your songs while distributing
              them to popular platforms like Spotify and Apple Music. This
              combination of NFT creation and traditional music distribution
              offers artists new ways to engage with their audience and monetize
              their work. Here's a quick overview of the straightforward process
              offered by Gram180:
            </p>

            <div className="items-list">
              <div className="items-list-item">
                <div className="item-box">
                  <img src={icoVerify} alt="Verify" />
                </div>
                <div className="item-body">
                  <h5>Artist Verification</h5>
                  <p>
                    PureTrade ensures that <strong>only original artists</strong>{" "}
                    can mint their music as NFTs. Our partnered distribution
                    platforms pre-approved the music before making it available
                    for minting on Gram180.
                  </p>
                </div>
              </div>
              <div className="items-list-item">
                <div className="item-box">
                  <img src={icoProcess} alt="Verify" />
                </div>
                <div className="item-body">
                  <h5>Simplified Minting and Purchasing Process</h5>
                  <p>
                    Our platform uses <strong>dollars</strong> instead of
                    cryptocurrencies for the minting process, making it more
                    accessible and familiar for artists and buyers alike.
                    There's no need for prior knowledge of cryptocurrencies,
                    allowing you to focus on your art. Plus, your fans can even
                    <strong>purchase your NFTs using a credit card!</strong>
                  </p>
                </div>
              </div>
              <div className="items-list-item">
                <div className="item-box">
                  <img src={icoAbastract} alt="Verify" />
                </div>
                <div className="item-body">
                  <h5>Crypto Abstraction</h5>
                  <p>
                    We've gone to great lengths to{" "}
                    <strong>simplify the complexities</strong> of
                    cryptocurrencies, resulting in a{" "}
                    <strong>user-friendly platform</strong>
                    emphasizing ease of use, dollar pricing, and accessibility.
                  </p>
                </div>
              </div>
              <div className="items-list-item">
                <div className="item-box">
                  <img src={icoCollections} alt="Verify" />
                </div>
                <div className="item-body">
                  <h5>Unique Collections</h5>
                  <p>
                    Each artist's collection on Gram180 is represented by its
                    <strong>own smart contract</strong> on the blockchain,
                    ensuring your music NFTs are uniquely tied to you. This
                    feature enables easy addition of your NFTs to{" "}
                    <a
                      href="https://opensea.io"
                      target="_blank"
                      rel="noreferrer"
                    >
                      OpenSea
                    </a>{" "}
                    and other marketplaces, thanks to compliance with all NFT
                    standards.
                  </p>
                  <p>
                    Our seamless integration with partnered distribution
                    platforms, such as <strong>Papaya Distro</strong>, allows
                    you to harness the power of NFTs without the complexity and
                    steep learning curve often associated with blockchain
                    technology. With our dedication to accessibility and ease of
                    use, you can create, sell, and manage your NFTs confidently,
                    while concentrating on your passion for music.
                  </p>
                  <p>
                    Discover the future of music ownership and monetization by
                    setting up an account with one of our{" "}
                    <strong>partnered distribution platforms</strong>, and take
                    advantage of Gram180's seamless integration. Check out our{" "}
                    <span style={{ color: "red" }}>list of partners</span> and
                    register today!
                  </p>
                </div>
              </div>
            </div>
            <h4>Why artists should consider pressing NFTs?</h4>
            <ul>
              <li>
                <strong>Unique ownership:</strong> Offer exclusive,
                one-of-a-kind digital assets
              </li>
              <li>
                <strong>Increased revenue:</strong> Profit from a new, exciting
                revenue stream
              </li>
              <li>
                <strong>Intellectual property protection:</strong> Authenticate
                and safeguard your creations
              </li>
              <li>
                <strong>Direct fan engagement:</strong> Foster closer
                relationships with dedicated supporters
              </li>
              <li>
                <strong>Long-term value:</strong> Benefit from potential value
                appreciation
              </li>
              <li>
                <strong>Access to a new market:</strong> Connect with music
                collectors and digital asset enthusiasts
              </li>
              <li>
                <strong>Enhanced fan loyalty:</strong> Heighten fan engagement
                through exclusivity and rarity
              </li>
            </ul>
          </Container>
        </div>
      </div>
    </main>
  );
}

export default HowWorkPage;
