import { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Industry from "../assets/images/homepage_background.webp";
import Carousel from "../components/Carousel";
import Aos from "aos";

import {
  ApplicationState,
  loadFeaturedArtist,
  loadTracksLatest,
} from "../store";
import { useSelector, useDispatch } from "react-redux";

function HomePage() {
  const dispatch = useDispatch();

  const { tracksLatest } = useSelector(
    (state: ApplicationState) => state.tracksLatestReducer
  );

  const { featuredArtist } = useSelector(
    (state: ApplicationState) => state.featuredArtistReducer
  );

  useEffect(() => {
    const loadData = async () => {
      await dispatch(loadTracksLatest());
      await dispatch(loadFeaturedArtist());
    };

    loadData();
  }, [dispatch]);



  useEffect(() => {
    Aos.init({
      duration: 1000,
      offset: 300,
    });
  }, []);

  return (
    <main className="page-content">
      <div className="pt-40 text-center pb-80">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={10} xl={8} data-aos="zoom-in">
              <h1>NFTs made easy and for everyone</h1>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="col-grid">
        <Container>
          <Row className="justify-content-lg-between justify-content-center">
            <Col xs={12} lg={12}>
              <div className="col-grid-image header-image col-grid-center mb-40">
                <img
                  src={Industry}
                  alt="Industry"
                  data-aos="fade-left"
                  data-aos-delay="200"
                />
                <h2 className="mb-40">
                  <span>Gold and NFTs: Unveiling the marketplace for tomorrow's treasures.</span>
                </h2>
              </div>

            </Col>
          </Row>
        </Container>
      </div>


      <div className="overflow-hidden" data-aos="fade-up">
        <Container className="pb-60 position-relative">
          <div className="section-subheading mb-2">
            <h6 className="text-uppercase mb-0">Latest Releases</h6>
          </div>

          <div className="mb-0">
            <Carousel tracks={tracksLatest} />
          </div>
          <div className="text-center">
            <Link to="/latest" className="btn btn-lg btn-solid">
              discover more NFTs
            </Link>
          </div>
        </Container>
      </div>

      {featuredArtist.length > 0 && (
        <div className="overflow-hidden">
          <Container
            fluid="md"
            className="pt-100 position-relative featured-artist"
          >
            <div className="section-subheading">
              <h6 className="text-uppercase mb-0">featured artist</h6>
            </div>

            {featuredArtist.map((item: any) => (
              <Row
                className="align-items-center gx-lg-5"
                key={JSON.stringify(item)}
              >
                <Col md={5} className="mb-md-0 mb-4" data-aos="fade-right">
                  <a href={item.link_url}>
                    <img
                      src={item.image_url}
                      alt={item.name}
                      className="img-fluid featured-img"
                    />
                  </a>
                </Col>
                <Col md={7} data-aos="fade-left">
                  <div className="section-subheading section-subheading-sm mb-2">
                    <h6 className="text-uppercase text-muted mb-0">
                      <a href={item.link_url}>{item.name}</a>
                    </h6>
                  </div>
                  <h2>
                    <a href={item.link_url}>{item.heading}</a>
                  </h2>
                  <p>{item.description}</p>
                </Col>
              </Row>
            ))}
          </Container>
        </div>
      )}



    </main>
  );
}

export default HomePage;
