import { combineReducers } from "redux";
import { ArtistReducer } from "./artistReducer";
import { ArtistsReducer } from "./artistsReducer";
import { LoadingReducer } from "./loadingReducer";
import { TracksLatestReducer } from "./tracksLatestReducer";
import { TracksLatestFilterReducer } from "./tracksLatestFilterReducer";
import { DetailReducer } from "./detailReducer";
import { FeaturedArtistReducer } from "./featuredArtistReducer";

const rootReducer = combineReducers({
  loadingReducer: LoadingReducer,
  artistReducer: ArtistReducer,
  artistsReducer: ArtistsReducer,
  tracksLatestReducer: TracksLatestReducer,
  tracksLatestFilterReducer: TracksLatestFilterReducer,
  detailReducer: DetailReducer,
  featuredArtistReducer: FeaturedArtistReducer,
});

export type ApplicationState = ReturnType<typeof rootReducer>;

export { rootReducer };
