import React, { forwardRef, useEffect, useImperativeHandle } from "react";
import { useContractRead } from "wagmi";

import USDC from "../../ABI/USDC.json";
import Loading from "../Loading";
import { enabledChains, USDC_CONTRACT } from "../../utils";

export const Allowance = forwardRef((props: any, ref) => {
  const { data, error, isLoading, refetch }: any = useContractRead({
    address: USDC_CONTRACT,
    abi: USDC,
    functionName: "allowance",
    chainId: enabledChains[0].id,
    args: [props.account, props.contract],
  });

  useEffect(() => {
    if (data) {
      props.onUpdateAllowance(JSON.parse(data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (error) {
      props.onError(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useImperativeHandle(
    ref,
    () => ({
      checkAllowance: () => {
        refetch();
      },
    }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <div className="Approve">
      {isLoading && <Loading width={80} height={80} />}
    </div>
  );
});
