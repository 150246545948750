import React, { useEffect } from "react";
import {
  useContractWrite,
  useWaitForTransaction,
  usePrepareContractWrite,
} from "wagmi";

import MineMintToken from "../../ABI/MineMintToken.json";
import { Button } from "react-bootstrap";
import Loading from "../Loading";
import { enabledChains } from "../../utils";

export default function Mint({
  contract,
  account,
  token,
  onError,
  onSuccess,
}: any) {
  const { config } = usePrepareContractWrite({
    address: contract,
    abi: MineMintToken,
    functionName: "mintUSDC",
    chainId: enabledChains[0].id,
    args: [account, token],
  });

  const { data, error, isLoading, write } = useContractWrite(config);
  const {
    data: transactionData,
    error: transactionError,
    isLoading: isLoadingTransaction,
  } = useWaitForTransaction({ hash: data?.hash });

  useEffect(() => {
    if (error) {
      onError(error);
    }
    // eslint-disable-next-line
  }, [error]);

  useEffect(() => {
    if (transactionError) {
      onError(transactionError);
    }
    // eslint-disable-next-line
  }, [transactionError]);

  useEffect(() => {
    if (transactionData) {
      onSuccess(transactionData);
    }
    // eslint-disable-next-line
  }, [transactionData]);

  return (
    <div className="Approve">
      {isLoading || isLoadingTransaction ? (
        <Loading width={80} height={80} />
      ) : (
        <>
          {write && (
            <Button
              variant="solid"
              onClick={async () => write()}
              className="mb-24"
            >
              MINT TRACK
            </Button>
          )}
        </>
      )}
    </div>
  );
}
