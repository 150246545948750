import React from "react";
import { Link } from "react-router-dom";
import { getImage, getRandomPlaceholder } from "../utils";
const placeholder = getRandomPlaceholder();

function ArtistItem({ image, name, tracks, permalink }: any) {
  return (
    <div className="card">
      <div
        className="card-body"
        style={{ textAlign: "center", paddingTop: 24 }}
      >
        <div className="mask3 artist-image3" style={{ position: "relative" }}>
          <Link to={"/artist/" + permalink}>
            <img
              src={image === "" ? placeholder : getImage(image)}
              alt={name}
            />
          </Link>
        </div>

        <h4>{name}</h4>
        <h6>NFts: {tracks}</h6>
        <hr />
        <div>
          <Link to={"/artist/" + permalink} className="btn btn-solid btn-xs">
            Author detail
          </Link>
        </div>
      </div>
    </div>
  );
}

export default ArtistItem;
