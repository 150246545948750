import React, { useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import PurpleShape from "../assets/images/purpleShape.svg";
import ErrorShape from "../assets/images/errorShape.svg";
import Wallet from "../assets/images/empty-wallet-change.svg";
import Music from "../assets/images/music-circle.svg";
import WalletTickError from "../assets/images/empty-wallet-remove.svg";


import axios from "axios";


import { useAccount, useDisconnect } from "wagmi";
import Approve from "./chain/Approve";
import { Allowance } from "./chain/Allowance";
import { BASE_URL } from "../utils";
import Mint from "./chain/Mint";
import MintSuccess from "./MintSuccess";
import { useWeb3Modal } from "@web3modal/react";

import { toast } from "react-toastify";

const WalletConnectModal = ({ contract, price, token }: any) => {
  const { address } = useAccount();

  const { disconnect } = useDisconnect();

  const allowanceRef = useRef(null) as any;

  const {open } = useWeb3Modal();

  const [show, setShow] = useState(false);
  const [showMint, setShowMint] = useState(false);
   
  const [showSuccess, setShowSuccess] = useState(false);
  // eslint-disable-next-line
  const [showError, setShowError] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");
  // eslint-disable-next-line
  const [allowance, setAllowance] = useState(0);

  useEffect(() => {
    if (address) {
      handleShow();
    } else {
      disconnect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);

  useEffect(() => {
    if (allowance === price) {
      handleClose();
      setShowMint(true);
    }
  }, [allowance, price]);

  const handleClose = () => {
    setShow(false);
  };

  const logout = () => {
    setShow(false);
    setShowMint(false);
    setShowSuccess(false);
    setShowError(false);
    disconnect();
  };

  const handleShow = () => setShow(true);

  // eslint-disable-next-line
  const handleShowMint = () => {
    setShowMint(true);
    setShow(false);
  };

  const handleCloseMint = () => {
    setShowMint(false);
  };

  const handleShowSuccess = () => setShowSuccess(true);
  const handleCloseSuccess = () => {
    setShowSuccess(false);
    //@ts-ignore
    window.location.reload(false);
  };

  const handleShowError = (err: string) => {
    if (err?.includes("invalid address or")) {
      return;
    }
    toast.warn(err, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

    setShowError(true);
    handleClose();
    handleCloseMint();
  };
  const handleCloseError = () => setShowError(false);

  useEffect(() => {
    if (allowance >= price) {
      handleClose();
      setShowMint(true);
    }
    // eslint-disable-next-line
  }, [allowance]);

  const formatError = (error: string) => {
    if (error) {
      return error.replace(/execution reverted: /g, "");
    } else {
      return "";
    }
  };

  const registerEvent = async (
    transaction: string,
    from: string,
    to: string,
    price: number,
    type: string,
    token: number,
    contract: string
  ) => {
    let key =
      transaction[4] +
      transaction[3] +
      transaction[6] +
      transaction[3] +
      transaction[1];
    // let res = await axios({
    //   url: `${BASE_URL}registerEvent.php?cache=${Date.now()}`,
    //   method: "POST",
    //   data: {
    //     transaction,
    //     from,
    //     to,
    //     price,
    //     type,
    //     token,
    //     contract,
    //     key,
    //   },
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    // });
    let res = await axios({
      url: `${BASE_URL}/api/v1/sale`,
      method: "POST",
      data: {
        transaction,
        from,
        to,
        price,
        type,
        token,
        contract,
        key,
      },
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (res.status === 200) {
    } else {
    }
  };

  return (
    <>
      {!address ? (
        <Button
          variant="solid"
          onClick={() => open()}
          className="btn btn-xs ms-auto buy-button"
        >
          CONNECT WALLET
        </Button>
      ) : (
        <Button
          variant="solid"
          onClick={() => handleShow()}
          className="btn btn-xs ms-auto buy-button"
        >
          CONNECT WALLET
        </Button>
      )}

      <Modal show={show} centered onHide={handleClose}>
        <Modal.Body className="text-center">
          <div className="d-flex justify-content-center">
            <div className="modal-icon">
              <img src={PurpleShape} alt="Approve" className="img-fluid" />
              <img src={Wallet} alt="Approve" className="modal-icon-front" />
            </div>
          </div>

          <h4 className="h4 mb-md-3 mb-1 font-primary">Just one more step</h4>
          <p className="mb-24 font-primary">
            As our mint price is in USDC, we need to ask for your approval to
            spend USDC from your wallet
          </p>

          <Allowance
            contract={contract}
            account={address}
            onUpdateAllowance={(value: number) => {
              if (value >= price) {
                handleClose();
                setShowMint(true);
              }
            }}
            ref={allowanceRef}
            onError={(e: any) => {
              setErrorMessage(formatError(e.reason));
              handleShowError(e.reason);
            }}
          />

          <Approve
            contract={contract}
            price={price}
            onSuccess={(e: any) => {
              allowanceRef?.current?.checkAllowance();
            }}
            onError={(e: any) => {
              setErrorMessage(formatError(e.reason));
              handleShowError(e.reason);
            }}
          />

          <button className="btn-disconnect" onClick={() => logout()}>
            Disconnect Wallet
          </button>
          <div className="code text-truncate">{address}</div>
        </Modal.Body>
      </Modal>

      <Modal show={showMint} centered onHide={handleCloseMint}>
        <Modal.Body className="text-center">
          <div className="d-flex justify-content-center">
            <div className="modal-icon">
              <img src={PurpleShape} alt="Mint" className="img-fluid" />
              <img src={Music} alt="Mint" className="modal-icon-front" />
            </div>
          </div>

          <h6 className="text-black">PRICE FOR ITEM</h6>
          <h4 className="mb-md-3 mb-1">{price / 1000000} USDC</h4>
          <Mint
            contract={contract}
            account={address}
            token={token}
            onSuccess={(e: any) => {
              handleCloseMint();
              handleShowSuccess();
              registerEvent(
                e.transactionHash,
                "0x0000000000000000000000000000000000000000",
                e.from,
                price,
                "mint",
                token,
                contract
              );
              disconnect();
            }}
            onError={(e: any) => {
              setErrorMessage(formatError(e.reason));
              handleShowError(e.reason);
            }}
          />

          <button className="btn-disconnect" onClick={() => logout()}>
            Disconnect Wallet
          </button>
          <div className="code text-truncate">{address}</div>
        </Modal.Body>
      </Modal>

      <MintSuccess
        show={showSuccess}
        onHide={handleCloseSuccess}
        handleCloseSuccess={handleCloseSuccess}
        contract={contract}
        account={address}
        token={token}
      />

      {/* Error */}
      <Modal show={false} centered onHide={handleCloseError}>
        <Modal.Body className="text-center">
          <div className="d-flex justify-content-center">
            <div className="modal-icon">
              <img src={ErrorShape} alt="Error" className="img-fluid" />
              <img
                src={WalletTickError}
                alt="Error"
                className="modal-icon-front"
              />
            </div>
          </div>

          <h4 className="h4 mb-md-3 mb-1">Error</h4>
          <p className="mb-24">{errorMessage}</p>

          <Button variant="solid" onClick={handleCloseError} className="mb-24">
            BACK TO GRAM
          </Button>
        </Modal.Body>
      </Modal>
      {/* Disconnect */}
    </>
  );
};

export default WalletConnectModal;
