import React from "react";
import Skeleton from "react-loading-skeleton";


function ArtistItemLoading() {
  return (
    <div className="card">
      <div
        className="card-body"
        style={{ textAlign: "center", paddingTop: 24 }}
      >
        <div className="mask3 artist-image3" style={{ position: "relative" }}>
          <Skeleton
            style={{ top: -8 }}
            width={84}
            height={100}
            baseColor={"#F1F4F8"}
            highlightColor={"#E5E8EB"}
          />
        </div>

        <h4>
          <Skeleton baseColor={"#F1F4F8"} highlightColor={"#E5E8EB"} />
        </h4>
        <h6>
          <Skeleton baseColor={"#F1F4F8"} highlightColor={"#E5E8EB"} />
        </h6>

        <hr />
        <div>
          <Skeleton baseColor={"#F1F4F8"} highlightColor={"#E5E8EB"} />
        </div>
      </div>
    </div>
  );
}

export default ArtistItemLoading;
